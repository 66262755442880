.content {
    padding-left: $sidebar-margin;
    transition: 0.3s;
}


// Applicants Section
.applicants {

    margin-top: 20px;

    h1 {
        color: #166585;
        font-weight: normal;
        font-size: 24px;
    }

}

// Quick Search Section
.quick-search {
    .input-search {
        max-width: 26rem;

        label {
            font-size: 15px;
            margin-bottom: 0.2rem;
        }
    }

    .search-desc {
        font-size: 12px;
        color: $gray-700;
    }
}

select.form-control,
input.form-control,
textarea.form-control {
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 1px rgba(0, 0, 0, 0.1);
}

// Applicants Table
.applicants-table {
    // background-color: #fff;
    // box-shadow: 0 2px 5px grey;
    // padding: 20px;

    .btn-resume,
    .btn-view {
        width: 150px !important;
        // margin-top: 10px;
    }

    .btn-resume {
        margin-right: 10px;
    }

    .filter label {
        font-size: 12px;
        margin-right: 10px;
        color: $gray-700;
    }

    .filter select {
        font-size: 14px;
        padding-right: 30px !important;
        background-size: 25px 25px;
        background-position: calc(100% - 2px) 4px;
        background-color: #fff !important;
    }

    .applicants-card {
        // border: 1px solid $gray-200;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        padding: 10px 10px 2px 10px;
        border-radius: 5px;
        background-color: #fff;

        .candidate-info {
            padding: 0 5px;
            border-bottom: 2px solid $gray-200;

            .name {
                color: #20558a;
                font-size: 20px;
                margin-right: 10px;
            }

            .status {
                text-align: center;
                font-size: 12px;
                padding: 0px 8px;
                background-color: #EEEEEE;
                border-radius: 13px;

                &.new {
                    background-color: #ffec91;
                }
            }

            .date-submitted {
                color: $gray-600;
                font-size: 14px;
            }

            .star {
                width: 35px;
                height: 35px;
                position: relative;
                cursor: pointer;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 30px;
                    height: 30px;
                    background-repeat: no-repeat;
                    background-size: 2em;
                }

                &.star-filled:after {
                    @include svg-star-filled(#f5c459);
                }

                &.star-outline:after {
                    @include svg-star-outline(#ddd);
                }
            }

        }

        .btn {
            font-size: 14px;
            height: 30px;
            padding: 4px;
            color: #20558a;
            background-color: #eaf1f7;
            border: none;
        }

        .title {
            font-size: 12px;
            font-weight: 600;
            margin: 10px 0 5px 0;
            line-height: 1.1em;
        }

        .description {
            font-size: 14px;
            color: $gray-700;
            margin: 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            line-height: 1.1em;
        }
    }
}

.box-line {
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #166585;
        background: linear-gradient(90deg, rgba(4, 140, 183, 1) 0%, rgba(5, 171, 223, 1) 70%);
    }
}

// Create Account page
.account-form {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 20px;

    label {
        font-size: 14px;
    }

    .form-section {
        margin-top: 15px;
        margin-bottom: 30px;
        border-radius: 5px;
        border: 1px solid $gray-400;
        padding: 10px 20px;

        input,
        textarea {
            background-color: #EEEEEE;
        }

        .input-search {

            // input {
            //     background-color: #fff;
            // }

            &:after {
                bottom: 6px;
            }
        }
    }

    // documents name styles
    .document-name {
        display: block;
        color: $gray-600;
        margin-bottom: 5px;
        font-size: 12px;
    }

    // Research checkboxes
    .research-checkbox {
        height: 200px;
        overflow-y: scroll;
        // position: relative;

        .checkbox-container {
            display: block;
            border-radius: 20px;
            position: relative;
            padding: 5px 0 5px 40px;
            margin-bottom: 5px;
            background-color: #dce6f1;
            cursor: pointer;
        }

        /* Hide the browser's default checkbox */
        .checkbox-container input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 3px;
            left: 10px;
            height: 18px;
            width: 18px;
            background-color: transparent;
        }

        /* On mouse-over, add a grey background color */
        .checkbox-container:hover, .checkbox-container:focus {
            background-color: #c6ddf5;
            transition: .3s;
        }

        /* When the checkbox is checked, add a blue background */
        .checkbox-container input:checked~.checkmark {
            background-color: $green;
            border-radius: 50px;
            margin-top: 3px;
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        /* Show the checkmark when checked */
        .checkbox-container input:checked~.checkmark:after {
            display: block;
        }

        /* Style the checkmark/indicator */
        .checkbox-container .checkmark:after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }
}

// Add Document button align
.btn-align {
    position: absolute;
    left: 0;
    bottom: 16px;

    @include media-breakpoint-down(md) {
        position: relative;
        margin-top: 20px;
    }
}