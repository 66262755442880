$sidebar-width: 310px;

.sidebar {
    // min-height: calc(100vh - 155px);
    position: absolute;
    background: #fff;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
    color: #363b43;
    height: 100%;
    left: -$sidebar-width;
    top: 145px;
    padding: 20px;
    // padding-left: 50px;
    transition: 0.3s;
    width: $sidebar-width;
    height: calc(100% - 145px); // -120px for header spacing
    // z-index: 300;

    select,
    input,
    textarea {
        background-color: $input-gray;
        border-color: $input-gray;
    }

    &.open {
        left: 0;

        +.content {
            padding-left: $sidebar-width + $sidebar-margin;

            .nav-tabs {
                @media (max-width: 992px) {
                    margin-top: 100px;

                    .btn-add {
                        left: 0;
                        top: -50px;
                    }
                }

            }
        }

    }


    .sidebar-trigger {
        background: rgb(255, 255, 255);
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 100%);
        border: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.12), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
        color: #545359;
        left: 272px;
        position: absolute;
        text-transform: uppercase;
        // box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.15), 2px 0px 1px -2px rgba(0, 0, 0, 0.12);
        top: 60px;
        transform: rotate(90deg);
        transition: 0.3s;

        &:hover,
        &:focus {
            // box-shadow: 2px -2px 2px 0px rgba(44, 44, 44, 0.19);
            background: rgb(222, 227, 239);
            background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(235, 239, 249, 1) 100%);
            border: 0;
            box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.22), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
        }
    }

    .sidebar-trigger img {
        display: inline-block;
        fill: #dee3ef;
        margin-left: 8px;
        width: 16px;
    }

    .sidebar-content {
        padding: 20px;
    }
}

@media (max-width: 766px) {
    .sidebar {
        top: 142px;
    }
}

@media (max-width: 568px) {
    .sidebar {
        top: 178px;
    }
}