#skip-nav {
    top: -30px;
    left: 3px;
    width: auto;
    height: auto;
    padding: 4px 8px;
    z-index: 1070;
    position: absolute;
    background-color: #f2f2f2;
    transition: .3s;
    border-radius: 5px;
    opacity: 0;
    &:focus {
        top: 3px;
        opacity: 1;
    }
  }
  
  .accessibility-nav {
    float: right;
    button {
        padding: 2px;
        min-width: 34px;
        min-height: 28px;
        text-align: center;
    }

    .icon-print {
        display: block;
        margin: auto;
        width: 20px;
        height: 20px;
        .color1 {
            fill: #fff;
        }
    }
}