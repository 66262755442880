#back-to-top {
    position: fixed;
    bottom: 30px;
    right: 45px;
    z-index: 1000;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 28px;
    font-size: 20px;
    opacity: 0;
    transition: .3s;
    pointer-events: none;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    &:hover,
    &:focus {
        text-decoration: none;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
    }
    #back-to-top-tip {
        padding: 3px 6px;
        position: absolute;
        top: 1em;
        margin-top: calc(-0.5em - 3px);
        right: 43px;
        width: 95px;
        overflow: visible;
        content: 'Back to Top';
        color: inherit;
        background-color: #ffffff;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        border-radius: 5px;
        opacity: 0;
        transition: .3s;
        pointer-events: none;
        white-space: nowrap;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    }
    &.active {
        opacity: 0.75;
        pointer-events: auto;
        &:hover {
            text-decoration: none;
            #back-to-top-tip {
                opacity: .9;
            }
        }
    }
    @include media-breakpoint-down(sm) {
        right: 15px;
        bottom: 15px;
        padding: 2px 6px;
        width: 31px;
        height: 31px;
        font-size: 18px;
        #back-to-top-tip {
            padding: 1px 4px;
            margin-top: calc(-0.5em - 1px);
            right: 33px;
            width: 75px;
            font-size: 12px;
            // opacity: .9;
        }
    }
}