//Content
body {
    font-family: 'Roboto', sans-serif;
    background-color: #F2F2F2;
    position: relative;
    height: 100%;
}

.container-fluid {
    max-width: $max-width-base;
    margin: 0 auto;
}

h2 {
    color: #166585;
    font-weight: normal;
}

// img[src$=".svg"] {
//     display: none;
// }

//buttons
.btn {
    color: #fff;
    transition: .3s;
    white-space: nowrap;

    &:hover {
        color: #fff;
    }

    &.btn-blue {
        width: 110px;
        background-color: $blue-primary;
        border-color: $blue-primary;

        &:hover,
        &:focus {
            background-color: lighten($blue-primary, 10%);
        }
    }

    &.btn-resume {
        width: 110px;
        background-color: #836a93;
        border-color: #836a93;

        &:hover,
        &:focus {
            background-color: lighten(#836a93, 10%);
        }
    }

    &.btn-darkGray {
        width: 110px;
        background-color: #757575;
        border-color: #757575;
        white-space: nowrap;

        &:hover,
        &:focus {
            background-color: lighten(#757575, 10%);
        }
    }

    &.btn-slanted {
        display: inline-block;
        // color: #fff;
        background-color: rgba(18, 54, 82, 0.8);
        transform: skew(-20deg);
        font-size: $font-size-sm;
        width: 62px;
        font-weight: normal;
        height: 28px;
        padding: 2px;
        border-radius: 0;

        &:hover {
            color: #fff;
            background: rgba(10, 31, 48, 0.9);
        }

        &>span {
            display: inline-block;
            transform: skew(20deg);
        }
    }

    &.btn-close {
        position: relative;
        opacity: 0.5;

        &:after {
            content: '×';
            position: absolute;
            color: $black;
            font-size: 20px;
            top: -10px;
        }

        &:hover,
        &:focus {
            opacity: 1;
            box-shadow: none;
        }

    }

    &.btn-add {
        position: relative;
        background: rgb(4, 140, 183);
        background: linear-gradient(90deg, rgba(4, 140, 183, 1) 0%, rgba(5, 171, 223, 1) 70%);
        padding: 3px 15px 3px 40px;
        opacity: 1;
        border-radius: 50px;

        &:before {
            @include add-svg($white);
            content: '';
            position: absolute;
            background-repeat: no-repeat;
            padding: 13px;
            display: block;
            left: 10px;
            transition: .3s;
        }

        &:hover,
        &:focus {
            opacity: 0.8;
        }

    }
}

.filter-btn {
    background-color: #E2F8FE;
    color: $black;
    border-radius: 20px;
    font-size: 12px;
    padding: 5px 10px;
    height: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
}

.applicants-filter-btn {
    background-color: #fff;
    border: none;
    padding: 8px 0px;
    width: calc(14% - 15px);
    height: 95px;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    margin-right: 20px;
    transition: .3s;

    &:last-child {
        margin-right: 0;
    }

    .applicants-num {
        font-size: 30px;
    }

    .applicants-stat {
        display: block;
        font-weight: 300;
        font-size: 14px;
        // line-height: 0.4rem;
    }

    &:first-child {
        border-top: 5px solid $box-1;
    }

    &:nth-child(2) {
        border-top: 5px solid $box-2;

    }

    &:nth-child(3) {
        border-top: 5px solid $box-3;

    }

    &:nth-child(4) {
        border-top: 5px solid $box-4;

    }

    &:nth-child(5) {
        border-top: 5px solid $box-5;

    }

    &:nth-child(6) {
        border-top: 5px solid $box-6;

    }

    &:nth-child(7) {
        border-top: 5px solid $box-7;
    }

    &:hover {
        -webkit-transform: scale(1.02, 1.02);
        transform: scale(1.02, 1.02);
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
    }

    &:focus {
        outline: none;
    }

    ///////////////////////
    @media (max-width: 1120px) {
        width: calc(25% - 20px);

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7) {
            width: calc(33.33% - 20px);
        }
    }
}

//Tooltip
.tooltip-icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;

    &:after {
        content: '';
        @include question-mark($blue-primary);
        position: absolute;
        width: 18px;
        height: 18px;
        bottom: -4px;
        background-repeat: no-repeat;
    }
}


// Forms
select {
    cursor: pointer;
    background-color: $input-gray !important;
    border-color: $input-gray !important;
    border-radius: 5px;
    appearance: none;
    -webkit-appearance: none;
    padding-right: 24px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: calc(100% - 5px) 5px;
    background-image: str-replace(url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='#{$gray-500}'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E"), "#", "%23");

}

.input-search {
    position: relative;

    &:after {
        content: '';
        @include svg-search($black);
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        right: 10px;
        bottom: 30px;
        background-size: 1em;

    }
}

// Toggle switch
.toggle-switch {
    width: 100%;

    .switch-group {
        background-color: #f2f2f2;
        border-radius: 18px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
        display: table;
        width: calc(100% - 4px);
        margin-bottom: 16px;
        margin-top: 3px;
        overflow: hidden;
        padding: 0 4px;
        position: relative;

        &::after {
            clear: both;
            content: '';
        }

        &.active-index-0 {
            &::before {
                left: 0;
            }
        }

        &.active-index-1 {
            label {
                &::before {
                    left: 100%;
                }
            }
        }

        &.active-index-2 {
            label {
                &::before {
                    left: 200%;
                }
            }
        }

        &.active-index-3 {
            label {
                &::before {
                    left: 300%;
                }
            }
        }
    }


    // checkbox
    input:checked+.label-text {
        // label text
        color: $blue-primary;
        font-weight: 700;
        // &.no {
        //     color: $red;
        // }

        // &.yes {
        //     color: $green;
        // }
    }

    // label
    label {
        display: table-cell;
        margin: 0;
        padding: 6px 14px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 50%;
        z-index: 10;

        &:first-child,
        &.checked-input {
            &::before {
                background-color: #fff;
                border-radius: 14px;
                box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
                content: '';
                height: calc(100% - (4px * 2));
                left: 0;
                position: absolute;
                top: 4px;
                transition: 0.3s;
                width: 100%;
                z-index: 1;
            }

            &.not-checked {
                &::before {
                    display: none;
                }
            }
        }

        &:first-child:nth-last-child(3),
        &:first-child:nth-last-child(3)~label {
            width: 33.333%;
        }

        &:first-child:nth-last-child(4),
        &:first-child:nth-last-child(4)~label {
            width: 25%;
        }

        input {
            display: none;
        }

    }

    .label-text {
        display: inline-block;
        font-size: 14px;
        line-height: 1.1em;
        position: relative;
        width: 100%;
        z-index: 15;
        color: #000;
        font-weight: normal;

    }

}


// Pagination
.pagination-custom {

    .page-link {
        background-color: transparent;
        border: none;
        color: $darkBlue;
        padding: 0.5rem 0.85rem;
        font-weight: 600;

        &:focus {
            box-shadow: none;
            opacity: 0.5;
        }
    }

    .previous {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            display: block;
            @include svg-nav-before($darkBlue);
            width: 16px;
            height: 16px;
            background-position: center;
            left: 0;
            opacity: 0.5;
        }
    }

    .next {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            @include svg-nav-next($darkBlue);
            display: block;
            width: 16px;
            height: 16px;
            background-position: center;
            right: 0;
        }
    }

    .active {
        color: #fff;
        background-color: $darkBlue;
        border-radius: 50%;
    }
}