@charset "UTF-8";

@font-face {
    font-family: Roboto;
    src: url("Fonts/Roboto/Roboto-Regular.ttf");
}

/* @font-face {
    font-family: Roboto;
    src: url("Fonts/Roboto/Roboto-Medium.ttf");
    font-weight: 400; } */

@font-face {
    font-family: Roboto;
    src: url("Fonts/Roboto/Roboto-Bold.ttf");
    font-weight: 900;
}

@font-face {
    font-family: Roboto;
    src: url("Fonts/Roboto/Roboto-LightItalic.ttf");
    font-style: italic;
    font-weight: 300;
}

.p-input-icon-left > .p-inputtext {
    padding-left: 2.5rem !important;
}


.p-button.p-button-outlined {
    height: 38px !important;
}

.p-jc-between {
    justify-content: space-between !important;
}

.p-d-flex {
    display: flex !important;
}

#skip-nav {
    top: -30px;
    left: 3px;
    width: auto;
    height: auto;
    padding: 4px 8px;
    z-index: 1070;
    position: absolute;
    background-color: #f2f2f2;
    transition: 0.3s;
    border-radius: 5px;
    opacity: 0;
}

    #skip-nav:focus {
        top: 3px;
        opacity: 1;
    }

.accessibility-nav {
    float: right;
}

    .accessibility-nav button {
        padding: 2px;
        min-width: 34px;
        min-height: 28px;
        text-align: center;
    }

    .accessibility-nav .icon-print {
        display: block;
        margin: auto;
        width: 20px;
        height: 20px;
    }

        .accessibility-nav .icon-print .color1 {
            fill: #fff;
        }

#back-to-top {
    position: fixed;
    bottom: 30px;
    right: 45px;
    z-index: 1000;
    cursor: pointer;
    width: 40px;
    height: 40px;
    padding: 5px 10px;
    background-color: #ffffff;
    border-radius: 28px;
    font-size: 20px;
    opacity: 0;
    transition: 0.3s;
    pointer-events: none;
    text-align: center;
    text-decoration: none;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
}

    #back-to-top:hover,
    #back-to-top:focus {
        text-decoration: none;
        box-shadow: 0 3px 15px rgba(0, 0, 0, 0.5);
    }

    #back-to-top #back-to-top-tip {
        padding: 3px 6px;
        position: absolute;
        top: 1em;
        margin-top: calc(-0.5em - 3px);
        right: 43px;
        width: 95px;
        overflow: visible;
        content: "Back to Top";
        color: inherit;
        background-color: #ffffff;
        text-align: center;
        font-size: 15px;
        font-weight: 400;
        border-radius: 5px;
        opacity: 0;
        transition: 0.3s;
        pointer-events: none;
        white-space: nowrap;
        box-shadow: 0 3px 10px rgba(0, 0, 0, 0.25);
    }

    #back-to-top.active {
        opacity: 0.75;
        pointer-events: auto;
    }

        #back-to-top.active:hover {
            text-decoration: none;
        }

            #back-to-top.active:hover #back-to-top-tip {
                opacity: 0.9;
            }

@media (max-width: 767.98px) {
    #back-to-top {
        right: 15px;
        bottom: 15px;
        padding: 2px 6px;
        width: 31px;
        height: 31px;
        font-size: 18px;
    }

        #back-to-top #back-to-top-tip {
            padding: 1px 4px;
            margin-top: calc(-0.5em - 1px);
            right: 33px;
            width: 75px;
            font-size: 12px;
        }
}

.flexslider {
    padding: 0;
    border: 0;
    overflow: hidden;
}

.slides > li {
    background-size: cover;
    height: 60vh;
    width: 100%;
    background-color: #fff;
    color: #000;
    text-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
}

    .slides > li > .container-fluid {
        background-size: cover;
        height: 100%;
    }

        .slides > li > .container-fluid.inverse {
            background-color: #000;
            color: #fff;
            text-shadow: 0 0 20px rgba(0, 0, 0, 0.8);
        }

        .slides > li > .container-fluid > .row {
            display: table;
            height: 100%;
            max-width: 50%;
        }

@media (max-width: 991.98px) {
    .slides > li > .container-fluid > .row {
        max-width: 75%;
    }
}

@media (max-width: 767.98px) {
    .slides > li > .container-fluid > .row {
        max-width: 100%;
    }
}

.slides > li > .container-fluid > .row > div {
    display: table-cell;
    padding-left: 4em;
    vertical-align: middle;
    transform: translateZ(0);
}

.flex-viewport {
    -webkit-transition: none;
    -moz-transition: none;
    transition: none;
}

.flex-direction-nav a {
    width: 42px;
    height: 42px;
}

.flex-direction-nav .flex-nav-prev a:before,
.flex-direction-nav .flex-nav-next a:before {
    content: "";
    font-family: sans-serif;
    width: 42px;
    height: 42px;
}

.flex-direction-nav .flex-nav-prev a:before {
    background-image: url("../images/icon-slider-arrow-left.svg");
}

.flex-direction-nav .flex-nav-next a:before {
    background-image: url("../images/icon-slider-arrow-right.svg");
}

.flex-control-nav {
    bottom: 10px;
}

.flex-control-paging li a {
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.25);
}

.navbar .navbar-nav ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

@media (min-width: 992px) {
    .navbar .navbar-nav > li {
        position: relative;
    }

        .navbar .navbar-nav > li > a > .sub-nav {
            display: none;
        }

        .navbar .navbar-nav > li .sub-nav {
            float: right;
        }

        .navbar .navbar-nav > li ul {
            position: absolute;
            top: 100%;
            left: 0;
            height: 0;
            min-width: 200px;
            background-color: #ffffff;
            z-index: 1;
            overflow: hidden;
            transition: 0.3s;
            box-shadow: 0 10px 10px rgba(0, 0, 0, 0.25);
        }

            .navbar .navbar-nav > li ul li {
                position: relative;
            }

            .navbar .navbar-nav > li ul ul {
                top: 0;
                left: 100%;
            }

    .navbar .navbar-nav .sub-nav-back {
        display: none;
    }
}

@media (max-width: 991.98px) {
    .navbar-collapse {
        overflow: hidden;
        transition: 0.3s;
    }

        .navbar-collapse > * {
            position: relative;
            transition: 0.3s;
        }

    .navbar-nav {
        margin: 0;
        transition: 0.3s;
        left: 0;
    }

        .navbar-nav .sub-nav {
            display: block !important;
            position: relative;
            top: -4px;
            float: right;
            font-size: 20px;
            transition: 0.3s;
        }

            .navbar-nav .sub-nav:hover {
                opacity: 0.7;
            }

        .navbar-nav .sub-nav-back {
            display: block !important;
            position: relative;
            padding: 10px 5px !important;
            font-size: 16px !important;
            z-index: 100;
            cursor: pointer;
        }

    .navbar-nav {
        position: relative;
    }

        .navbar-nav li a {
            display: block;
            margin: 0 !important;
            padding: 10px;
        }

        .navbar-nav > li {
            position: initial;
            padding: 0;
            text-align: left;
        }

            .navbar-nav > li a {
                float: none;
            }

            .navbar-nav > li:hover > ul {
                border-top-width: 0;
            }

            .navbar-nav > li ul {
                position: absolute !important;
                top: 0;
                left: 100% !important;
                height: auto;
                width: 100%;
            }

                .navbar-nav > li ul.last {
                    left: 100% !important;
                    right: auto !important;
                }

            .navbar-nav > li li {
                position: initial !important;
            }

            .navbar-nav > li.active > ul {
                padding-top: 0;
                height: auto;
            }

                .navbar-nav > li.active > ul > li {
                    top: auto;
                }

            .navbar-nav > li > ul {
                padding-left: 0 !important;
                width: 100% !important;
                height: auto;
                opacity: 1;
                pointer-events: auto;
            }

                .navbar-nav > li > ul > li {
                    display: block;
                    top: auto;
                    left: auto;
                    margin-right: auto;
                }

                    .navbar-nav > li > ul > li:first-child,
                    .navbar-nav > li > ul > li:first-child + li {
                        left: auto;
                        margin-right: auto;
                    }

                        .navbar-nav > li > ul > li:first-child > a,
                        .navbar-nav > li > ul > li:first-child + li > a {
                            left: auto !important;
                            margin-right: auto !important;
                        }

                    .navbar-nav > li > ul > li:hover > ul {
                        height: auto;
                    }

                    .navbar-nav > li > ul > li .sub-nav {
                        top: -12px;
                    }

            .navbar-nav > li:hover > ul {
                height: auto;
                border-top-width: 0;
            }

            .navbar-nav > li > ul > li > ul {
                position: relative;
                top: 0;
                height: auto;
                opacity: 1;
            }
}

table tbody th {
    color: #007bff;
}

@media (min-width: 992px) {
    table thead th {
        background-color: #007bff;
        color: #fff;
        padding: 0.5em 0.75em;
    }

    table tbody th {
        color: #007bff;
        padding: 0.25em 0.75em;
    }

    table td {
        padding: 0.25em 0.75em;
    }
}

@media (max-width: 991.98px) {
    table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    table tr {
        border-bottom: 3px solid #007bff;
        display: block;
    }

        table tr:first-child {
            border-top: 3px solid #007bff;
        }

    table td {
        border-bottom: 1px solid #ddd;
        display: block;
    }

        table td:before {
            display: table-cell;
            content: attr(data-label);
            float: left;
            margin-right: 0.4em;
            color: #007bff;
        }
}

.table-stripe-vertical td:nth-child(2n) {
    background-color: #f8f9fa;
}

.table-stripe-horizontal tr:nth-child(2n) {
    background-color: #f8f9fa;
}

body {
    font-family: 'Roboto', sans-serif;
    background-color: #F2F2F2;
    position: relative;
    height: 100%;
    min-height: 100vh;
}

.navbar {
    min-height: 45px;
}

.container-fluid {
    max-width: 1420px;
    margin: 0 auto;
}

.content {
    padding-bottom: 5rem;
}

h2 {
    color: #166585;
    font-weight: normal;
}

.btn {
    color: #fff;
    transition: 0.3s;
    white-space: nowrap;
}

    .btn:hover {
        color: #fff;
    }

    .btn.btn-blue {
        background-color: #0477a5;
        border-color: #0477a5;
    }

        .btn.btn-blue:hover,
        .btn.btn-blue:focus {
            background-color: #059bd7;
        }

    .btn.btn-resume {
        width: 110px;
        background-color: #836a93;
        border-color: #836a93;
    }

        .btn.btn-resume:hover,
        .btn.btn-resume:focus {
            background-color: #9c87a9;
        }

    .btn.btn-darkGray {
        /* width: 110px; */
        background-color: #757575;
        border-color: #757575;
        white-space: nowrap;
    }

        .btn.btn-darkGray:hover,
        .btn.btn-darkGray:focus {
            background-color: #8f8f8f;
        }

    .btn.btn-slanted {
        display: inline-block;
        background-color: rgb(0 100 113);
        transform: skew(-20deg);
        font-size: 0.875rem;
        width: 62px;
        font-weight: normal;
        height: 28px;
        padding: 2px;
        border-radius: 0;
    }

        .btn.btn-slanted:hover {
            color: #fff;
            background: rgba(10, 31, 48, 0.9);
        }

        .btn.btn-slanted > span {
            display: inline-block;
            transform: skew(20deg);
        }

    .btn.btn-close {
        position: relative;
        opacity: 0.5;
    }

        .btn.btn-close:after {
            content: "×";
            position: absolute;
            color: #000;
            font-size: 20px;
            top: -10px;
        }

        .btn.btn-close:hover,
        .btn.btn-close:focus {
            opacity: 1;
            box-shadow: none;
        }

    .btn.btn-fixed-width {
        width: 110px;
    }

    .btn.btn-add {
        position: relative;
        background: #048cb7;
        background: linear-gradient(90deg, #048cb7 0%, #05abdf 70%);
        padding: 3px 15px 3px 40px;
        opacity: 1;
        border-radius: 50px;
    }

        .btn.btn-add:before {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24' viewBox='0 0 24 24' width='24'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath  fill='%23fff' d='M13 7h-2v4H7v2h4v4h2v-4h4v-2h-4V7zm-1-5C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z'/%3E%3C/svg%3E");
            content: "";
            position: absolute;
            background-repeat: no-repeat;
            padding: 13px;
            display: block;
            left: 10px;
            transition: 0.3s;
        }

        .btn.btn-add:hover,
        .btn.btn-add:focus {
            opacity: 0.8;
        }

    .btn.btn-white {
        background: #fff;
        border: solid 2px #555;
        box-sizing: border-box;
        color: #555;
    }

        .btn.btn-white:hover,
        .btn.btn-white:focus {
            background: #e6e6e6;
            transition: 0.3s;
        }

    .btn.btn-back {
        color: #495057;
        position: relative;
        padding-left: 25px;
    }

        .btn.btn-back:before {
            content: "";
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23495057'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
            width: 20px;
            height: 20px;
            left: 0;
        }

    .btn.btn-download {
        background-color: #28465d;
        width: 100%;
        padding-left: 40px;
    }

        .btn.btn-download span {
            position: relative;
        }

            .btn.btn-download span:before {
                content: "";
                position: absolute;
                background-image: url(../images/download.svg);
                background-size: 18px;
                width: 18px;
                height: 18px;
                left: -25px;
                top: 0px;
            }

        .btn.btn-download:hover,
        .btn.btn-download:focus {
            background-color: #0477a5;
        }

.filter-btn {
    background-color: #e2f8fe;
    color: #000;
    border-radius: 15px;
    font-size: 12px;
    padding: 5px 10px;
    min-height: 30px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    margin: 0.15rem;
}

.applicants-filter-btn {
    background-color: #fff;
    border: none;
    padding: 8px 0px;
    width: calc(12% - 15px);
    height: 100px;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    margin-top: 20px;
    margin-right: 20px;
    transition: 0.3s;
}

.applicants-Skeleton-filter-btn {
    border: none;
    padding: 8px 0px;
    width: calc(12% - 15px) !important;
    height: 100px !important;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    margin-top: 20px;
    margin-right: 20px;
    transition: 0.3s;
}

.applicants-filter-btn-width {
    min-width: 8rem !important;
}

.applicants-filter-btn:last-child {
    margin-right: 0;
}

.applicants-filter-btn .applicants-num {
    font-size: 30px;
}

.applicants-filter-btn .applicants-stat {
    display: block;
    font-weight: 300;
    font-size: 14px;
}

.applicants-filter-btn:first-child {
    border-top: 5px solid #1b769b;
}

.applicants-filter-btn:nth-child(2) {
    border-top: 5px solid #1d96a5;
}

.applicants-filter-btn:nth-child(3) {
    border-top: 5px solid #349c89;
}

.applicants-filter-btn:nth-child(4) {
    border-top: 5px solid #189378;
}

.applicants-filter-btn:nth-child(5) {
    border-top: 5px solid #127864;
}

.applicants-filter-btn:nth-child(6) {
    border-top: 5px solid #0f624c;
}

.applicants-filter-btn:nth-child(7) {
    border-top: 5px solid #053c2c;
}

.applicants-filter-btn:nth-child(8) {
    border-top: 5px solid #053c2c;
}

.applicants-filter-btn:hover {
    -webkit-transform: scale(1.02, 1.02);
    transform: scale(1.02, 1.02);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.35);
}

.applicants-filter-btn:focus {
    outline: none;
}

@media (max-width: 1120px) {
    .applicants-filter-btn {
        width: calc(25% - 20px);
    }

        .applicants-filter-btn .applicants-filter-btn-width {
            width: calc(38% - 20px);
        }

        .applicants-filter-btn:nth-child(5),
        .applicants-filter-btn:nth-child(6),
        .applicants-filter-btn:nth-child(7) {
            width: calc(33.33% - 20px);
        }
}


.btn-guide {
    width: 100%;
    background: #fff;
    color: #333;
    font-size: 22px;
    position: relative;
    padding: 0;
    border-radius: 5px;
    border: 0;
    display: flex;
    align-items: center;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
    transition: box-shadow 0.3s;
}

    .btn-guide:before {
        content: "";
        display: block;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        width: 5px;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .btn-guide.btn-guide-green .chevron-right {
        background: linear-gradient(#038a6b 30%, #04bc92 80%);
    }

    .btn-guide.btn-guide-blue .chevron-right {
        background: linear-gradient(#047199 30%, #059bd2 80%);
    }

    .btn-guide .document-details {
        padding-left: 15px;
        text-align: left;
        align-items: center;
        width: 100%;
    }

        .btn-guide .document-details .document-name .document-title {
            font-size: 16px;
            font-weight: 500;
        }

        .btn-guide .document-details .document-name .document-fullname {
            font-size: 14px;
            color: #6c757d;
        }

        .btn-guide .document-details .document-info {
            font-size: 14px;
            color: #6c757d;
            text-align: right;
        }

        .btn-guide .document-details .document-fullname,
        .btn-guide .document-details .document-size,
        .btn-guide .document-details .document-date {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

            .btn-guide .document-details .document-fullname.document-size,
            .btn-guide .document-details .document-size.document-size,
            .btn-guide .document-details .document-date.document-size {
                margin-top: 12px;
            }

    .btn-guide .chevron-right {
        min-width: 90px;
        right: 0;
        margin-left: auto;
        position: relative;
        border-radius: 0 5px 5px 0;
    }

        .btn-guide .chevron-right:after {
            content: "";
            background-image: url("../images/doc-technical.f1e1a2ba.svg");
            display: inline-block;
            height: 40px;
            left: 37%;
            margin: auto;
            position: absolute;
            top: 26%;
            width: 40px;
        }

    .btn-guide:hover,
    .btn-guide:focus {
        box-shadow: 0 8px 20px rgba(33, 33, 33, 0.2);
    }

        .btn-guide:hover.btn-guide-green .document-name,
        .btn-guide:focus.btn-guide-green .document-name {
            color: #038a6b;
        }

        .btn-guide:hover.btn-guide-blue .document-name,
        .btn-guide:focus.btn-guide-blue .document-name {
            color: #047199;
        }

.btn-delete {
    transition: .3s;
}

    .btn-delete .delete-icon {
        position: relative;
        display: block;
        width: 30px;
        height: 30px;
        background-color: #f7ebe7;
        border-radius: 0.25rem;
    }

        .btn-delete .delete-icon:after {
            content: '';
            position: absolute;
            top: 6px;
            left: 6px;
            width: 18px;
            height: 18px;
            background-color: #6f5048;
            -webkit-mask-image: url(../images/icon-trash.svg);
            mask-image: url(../images/icon-trash.svg);
        }

    .btn-delete:hover .delete-icon:after, .btn-delete:focus .delete-icon:after {
        background-color: red;
    }

.buttons-container {
    display: flex;
    justify-content: end;
}

    .buttons-container .buttons-container-item:not(:last-child) {
        margin-right: 10px;
    }

.tooltip-icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
}

    .tooltip-icon:after {
        content: "";
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='18px' viewBox='0 0 24 24' width='18px' fill='%230477a5'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z'/%3E%3C/svg%3E");
        position: absolute;
        width: 18px;
        height: 18px;
        bottom: -4px;
        background-repeat: no-repeat;
    }

select {
    cursor: pointer;
    background-color: #eeeeee !important;
    border-color: #eeeeee !important;
    border-radius: 5px;
    appearance: none;
    -webkit-appearance: none;
    padding-right: 24px;
    background-repeat: no-repeat;
    background-size: 30px 30px;
    background-position: calc(100% - 5px) 5px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23adb5bd'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z'/%3E%3C/svg%3E");
}

.input-search {
    position: relative;
}

    .input-search:after {
        content: "";
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 56.3 57.4' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23000' d='M40.5,35.6c2.7-3.7,4.3-8.3,4.3-13.2C44.8,10,34.8,0,22.4,0C10,0,0,10,0,22.4c0,12.4,10,22.4,22.4,22.4 c4.4,0,8.5-1.3,12-3.5l16.1,16.1l5.8-5.8L40.5,35.6z M22.3,38.9c-9.1,0-16.5-7.4-16.5-16.5c0-9.1,7.4-16.5,16.5-16.5 c9.1,0,16.5,7.4,16.5,16.5C38.8,31.5,31.4,38.9,22.3,38.9z'/%3E%3C/svg%3E");
        position: absolute;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        right: 10px;
        bottom: 30px;
        background-size: 1em;
    }

.toggle-switch {
    width: 100%;
}

    .toggle-switch .switch-group {
        background-color: #f2f2f2;
        border-radius: 18px;
        box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.15);
        display: table;
        width: calc(100% - 4px);
        margin-bottom: 16px;
        margin-top: 3px;
        overflow: hidden;
        padding: 0 4px;
        position: relative;
    }

        .toggle-switch .switch-group::after {
            clear: both;
            content: "";
        }

        .toggle-switch .switch-group.active-index-0::before {
            left: 0;
        }

        .toggle-switch .switch-group.active-index-1 label::before {
            left: 100%;
        }

        .toggle-switch .switch-group.active-index-2 label::before {
            left: 200%;
        }

        .toggle-switch .switch-group.active-index-3 label::before {
            left: 300%;
        }

    .toggle-switch input:checked + .label-text {
        color: #0477a5;
        font-weight: 700;
    }

    .toggle-switch label {
        display: table-cell;
        margin: 0;
        padding: 6px 14px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        width: 50%;
        z-index: 10;
    }

        .toggle-switch label:first-child::before,
        .toggle-switch label.checked-input::before {
            background-color: #fff;
            border-radius: 14px;
            box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
            content: "";
            height: calc(100% - (4px * 2));
            left: 0;
            position: absolute;
            top: 4px;
            transition: 0.3s;
            width: 100%;
            z-index: 1;
        }

        .toggle-switch label:first-child.not-checked::before,
        .toggle-switch label.checked-input.not-checked::before {
            display: none;
        }

        .toggle-switch label:first-child:nth-last-child(3),
        .toggle-switch label:first-child:nth-last-child(3) ~ label {
            width: 33.333%;
        }

        .toggle-switch label:first-child:nth-last-child(4),
        .toggle-switch label:first-child:nth-last-child(4) ~ label {
            width: 25%;
        }

        .toggle-switch label input {
            display: none;
        }

    .toggle-switch .label-text {
        display: inline-block;
        font-size: 14px;
        line-height: 1.1em;
        position: relative;
        width: 100%;
        z-index: 15;
        color: #000;
        font-weight: normal;
    }

.pagination-custom .page-link {
    background-color: transparent;
    border: none;
    color: #28465d;
    padding: 0.5rem 0.85rem;
    font-weight: 600;
}

    .pagination-custom .page-link:focus {
        box-shadow: none;
        opacity: 0.5;
    }

.pagination-custom .previous {
    position: relative;
}

    .pagination-custom .previous:after {
        content: "";
        position: absolute;
        display: block;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2328465D'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
        width: 16px;
        height: 16px;
        background-position: center;
        left: 0;
        opacity: 0.5;
    }

.pagination-custom .next {
    position: relative;
}

    .pagination-custom .next:after {
        content: "";
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2328465D'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z'/%3E%3C/svg%3E");
        display: block;
        width: 16px;
        height: 16px;
        background-position: center;
        right: 0;
    }

.pagination-custom .active {
    color: #fff;
    background-color: #28465d;
    border-radius: 50%;
}

.top-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 25px;
    background: url(../images/header.jpg), #28465d;
    background-position: center;
    background-size: cover;
}

@media (max-width: 568px) {
    .top-header {
        flex-direction: column;
    }

        .top-header .user-info {
            margin-top: 20px;
        }
}

.top-header .nih-details {
    display: flex;
}

    .top-header .nih-details .logo-nih {
        display: flex;
        fill: white;
        padding: 5px 14px 5px 0;
        position: relative;
        z-index: 10;
    }

        .top-header .nih-details .logo-nih .logo-nih-mark {
            width: 100px;
        }

        .top-header .nih-details .logo-nih .logo-nih-niaid-text {
            width: 150px;
        }

@media (max-width: 767.98px) {
    .top-header .nih-details .logo-nih .logo-nih-niaid-text {
        display: none;
    }
}

.top-header .nih-details .application-details {
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 16px;
    position: relative;
    z-index: 700;
}

    .top-header .nih-details .application-details h1 {
        font-size: 26px;
        font-weight: normal;
        margin-bottom: 0;
    }

@media (max-width: 900px) {
    .top-header .nih-details .application-details h1 {
        font-size: 0;
        visibility: hidden;
    }

        .top-header .nih-details .application-details h1::after {
            content: "PostDoc";
            display: block;
            font-size: 26px;
            visibility: visible;
        }
}

.top-header .nih-details .application-details::before {
    background: rgba(255, 255, 255, 0.5);
    content: "";
    display: block;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 1.5px;
}

.top-header .user-block {
    display: flex;
    margin-left: auto;
    margin-right: 0;
    align-items: center;
}

    .top-header .user-block .user-status {
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-right: 30px;
        font-size: 0.875rem;
        line-height: 1.3em;
        color: #fff;
    }

        .top-header .user-block .user-status .user-name {
            font-weight: 700;
        }

.navbar {
    background-color: #0c2d49;
    padding-bottom: 0;
    padding-left: 20px;
    padding-top: 6px;
    text-shadow: none;
    z-index: 10;
}

@media (max-width: 576px) {
    .navbar {
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

        .navbar.navbar-dark .nav-item {
            margin-bottom: 5px;
        }

            .navbar.navbar-dark .nav-item:last-child {
                margin-bottom: 0;
            }

            .navbar.navbar-dark .nav-item .nav-link {
                border-radius: 5px;
                padding-bottom: 5px;
                padding-top: 5px;
            }

            .navbar.navbar-dark .nav-item.active .nav-link {
                background: #207a9b;
                color: #fff;
            }
}

.navbar-expand-lg .navbar-nav .nav-link,
.navbar-expand-md .navbar-nav .nav-link {
    border-radius: 5px 5px 0 0;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-right: 5px;
    padding: 3px 20px 9px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .navbar-expand-lg .navbar-nav .nav-link,
    .navbar-expand-md .navbar-nav .nav-link {
        border-radius: 0;
    }
}

.navbar-expand-lg .navbar-nav .nav-item:not(.active) .nav-link:focus,
.navbar-expand-lg .navbar-nav .nav-item:not(.active) .nav-link:hover,
.navbar-expand-md .navbar-nav .nav-item:not(.active) .nav-link:focus,
.navbar-expand-md .navbar-nav .nav-item:not(.active) .nav-link:hover {
    background-color: #1a637d;
    color: #fff;
}

.navbar-dark .nav-item.active .nav-link {
    background-color: #f1f4f5;
    color: #123b56;
}

.navbar-dark .navbar-toggler {
    color: #fff;
    margin-bottom: 6px;
    padding: 0.25rem 0.5rem;
}

.content {
    padding-left: 45px;
    transition: 0.3s;
    min-height: 777px;
}

.applicants {
    margin-top: 20px;
}

    .applicants h1 {
        color: #166585;
        font-weight: normal;
        font-size: 24px;
    }

.quick-search .input-search {
    width: 26rem;
}


    .quick-search .input-search label {
        font-size: 15px;
        margin-bottom: 0.2rem;
    }

.quick-search .search-desc {
    font-size: 12px;
    color: #495057;
}

select.form-control,
input.form-control,
textarea.form-control {
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px 1px rgba(0, 0, 0, 0.1);
}

.applicants-table .btn-resume,
.applicants-table .btn-view {
    width: 150px !important;
}

.applicants-table .btn-resume {
    margin-right: 10px;
}

.applicants-table .filter label {
    font-size: 12px;
    margin-right: 10px;
    color: #495057;
}

.applicants-table .filter select {
    font-size: 14px;
    padding-right: 30px !important;
    background-size: 25px 25px;
    background-position: calc(100% - 2px) 4px;
    background-color: #fff !important;
}

.applicants-table .applicants-card {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 10px 10px 2px 10px;
    border-radius: 5px;
    background-color: #fff;
}

    .applicants-table .applicants-card .candidate-info {
        padding: 0 5px;
        border-bottom: 2px solid #e9ecef;
    }

        .applicants-table .applicants-card .candidate-info .name {
            color: #20558a;
            font-size: 20px;
            margin-right: 10px;
        }

        .applicants-table .applicants-card .candidate-info .status {
            text-align: center;
            font-size: 12px;
            padding: 0px 8px;
            background-color: #eeeeee;
            border-radius: 13px;
        }

            .applicants-table .applicants-card .candidate-info .status.new {
                background-color: #ffec91;
            }

        .applicants-table .applicants-card .candidate-info .date-submitted {
            color: #6c757d;
            font-size: 14px;
        }

        .applicants-table .applicants-card .candidate-info .star {
            width: 35px;
            height: 35px;
            position: relative;
            cursor: pointer;
        }

            .applicants-table .applicants-card .candidate-info .star:after {
                content: "";
                display: block;
                position: absolute;
                width: 30px;
                height: 30px;
                background-repeat: no-repeat;
                background-size: 2em;
            }

            .applicants-table .applicants-card .candidate-info .star.star-filled:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23f5c459'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z'/%3E%3C/svg%3E");
            }

            .applicants-table .applicants-card .candidate-info .star.star-outline:after {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23ddd'%3E%3Cpath d='M22 9.24l-7.19-.62L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21 12 17.27 18.18 21l-1.63-7.03L22 9.24zM12 15.4l-3.76 2.27 1-4.28-3.32-2.88 4.38-.38L12 6.1l1.71 4.04 4.38.38-3.32 2.88 1 4.28L12 15.4z'/%3E%3C/svg%3E");
            }

    .applicants-table .applicants-card .btn {
        font-size: 14px;
        height: 30px;
        padding: 4px;
        color: #20558a;
        background-color: #eaf1f7;
        border: none;
    }

    .applicants-table .applicants-card .title {
        font-size: 12px;
        font-weight: 600;
        margin: 10px 0 5px 0;
        line-height: 1.1em;
    }

    .applicants-table .applicants-card .description {
        font-size: 15px;
        color: #495057;
        margin: 0;
        text-overflow: ellipsis;
        /* white-space: nowrap; */
        overflow: hidden;
        line-height: 1.1em;
    }

.box-line {
    position: relative;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}

    .box-line:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #166585;
        background: linear-gradient(90deg, #048cb7 0%, #05abdf 70%);
    }

.account-form {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 20px;
}

    .account-form label {
        font-size: 14px;
    }

    .account-form .form-section {
        margin-top: 15px;
        margin-bottom: 30px;
        border-radius: 5px;
        border: 1px solid #ced4da;
        padding: 10px 20px;
    }

        .account-form .form-section input,
        .account-form .form-section textarea {
            background-color: #eeeeee;
        }

        .account-form .form-section .input-search:after {
            bottom: 6px;
        }

    .account-form .document-name {
        display: block;
        color: #6c757d;
        margin-bottom: 5px;
        font-size: 12px;
    }

    .account-form .research-checkbox {
        height: 200px;
        overflow-y: scroll;
        /* Hide the browser's default checkbox */
        /* Create a custom checkbox */
        /* On mouse-over, add a grey background color */
        /* When the checkbox is checked, add a blue background */
        /* Create the checkmark/indicator (hidden when not checked) */
        /* Show the checkmark when checked */
        /* Style the checkmark/indicator */
    }

        .account-form .research-checkbox .checkbox-container {
            display: block;
            border-radius: 20px;
            position: relative;
            padding: 5px 0 5px 40px;
            margin-bottom: 5px;
            background-color: #dce6f1;
            cursor: pointer;
        }

            .account-form .research-checkbox .checkbox-container input {
                position: absolute;
                opacity: 0;
                cursor: pointer;
                height: 0;
                width: 0;
            }

        .account-form .research-checkbox .checkmark {
            position: absolute;
            top: 3px;
            left: 10px;
            height: 18px;
            width: 18px;
            background-color: transparent;
        }

        .account-form .research-checkbox .checkbox-container:hover,
        .account-form .research-checkbox .checkbox-container:focus {
            background-color: #c6ddf5;
            transition: 0.3s;
        }

        .account-form
        .research-checkbox
        .checkbox-container
        input:checked ~ .checkmark {
            background-color: #28a745;
            border-radius: 50px;
            margin-top: 3px;
        }

        .account-form .research-checkbox .checkmark:after {
            content: "";
            position: absolute;
            display: none;
        }

        .account-form
        .research-checkbox
        .checkbox-container
        input:checked ~ .checkmark:after {
            display: block;
        }

        .account-form .research-checkbox .checkbox-container .checkmark:after {
            left: 7px;
            top: 3px;
            width: 5px;
            height: 10px;
            border: solid white;
            border-width: 0 1px 1px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }

.btn-align {
    position: absolute;
    left: 0;
    bottom: 16px;
}

@media (max-width: 991.98px) {
    .btn-align {
        position: relative;
        margin-top: 20px;
    }
}

.btn-round {
    position: relative;
    background: #fff;
    border-radius: 25px;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
    margin-left: 8px;
    padding: 3px 8px;
    transition: 0s;
    color: #3272a7;
    font-size: 14px;
    font-weight: 500;
}

    .btn-round.icon-download {
        padding-right: 30px;
    }

        .btn-round.icon-download:before {
            content: "";
            position: absolute;
            background-image: url(../images/download-blue.svg);
            background-size: 14px;
            width: 14px;
            height: 14px;
            right: 10px;
            top: 6px;
        }

    .btn-round:hover,
    .btn-round:focus {
        background: #3272a7;
        color: #fff;
    }

        .btn-round:hover.icon-download:before,
        .btn-round:focus.icon-download:before {
            background-image: url(../images/download.svg);
        }

.detail-header {
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
    height: 75px;
    padding: 0;
    z-index: 10;
    position: relative;
}

.detail-row {
    background: #e6e6e9;
    display: flex;
    height: 100%;
    justify-content: space-between;
    width: 100%;
}

.fixed-header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100;
}

.account-name {
    font-size: 24px;
    font-weight: bold;
    margin-left: 15px;
}

#group {
    padding-right: 35px;
    background-color: white !important;
}

label {
    margin-bottom: 2px;
    font-size: 14px;
}

.buttons-group div:nth-child(2) {
    position: relative;
    /* padding-right: 30px; */
}

/* .buttons-group div:nth-child(2):after {
  background-color: #ced4da;
  content: "";
  display: block;
  position: absolute;
  height: 70px;
  right: 0px;
  width: 1.5px;
} */

@media (max-width: 505px) {
    .buttons-group div:nth-child(3):after {
        display: none;
    }
}

/* .buttons-group div:nth-child(2) {
  padding-top: 10px;
}

@media (max-width: 767.98px) {
  .buttons-group div:nth-child(2) {
    padding: 10px 0;
  }
} */

.detail-section {
    background: #fff;
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
    margin-bottom: 35px;
    padding: 20px;
    position: relative;
    width: 100%;
}

    .detail-section:before {
        content: "";
        display: block;
        height: 4px;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        background: #08b5b3;
    }

    .detail-section ~ .detail-section:before {
        background: #23ad8d;
    }

    .detail-section .section-head {
        display: flex;
        flex-direction: row;
        margin-bottom: 15px;
        margin-top: 5px;
    }

    .detail-section .section-circle {
        border-radius: 100%;
        height: 40px;
        position: relative;
        width: 40px;
    }

        .detail-section .section-circle .section-icon {
            display: block;
            height: 24px;
            left: 8px;
            position: absolute;
            top: 8px;
            width: 24px;
            background-size: cover;
        }

    .detail-section.education-section:before {
        background: #0580ad;
    }

    .detail-section.education-section .section-circle {
        background: #0580ad;
    }

        .detail-section.education-section .section-circle .section-icon {
            background-image: url(../images/education.svg);
        }

    .detail-section.certifications-section:before {
        background: #089bb5;
    }

    .detail-section.certifications-section .section-circle {
        background: #089bb5;
    }

        .detail-section.certifications-section .section-circle .section-icon {
            background-image: url(../images/certifications.svg);
        }

    .detail-section.research-section:before {
        background: #08b5b3;
    }

    .detail-section.research-section .section-circle {
        background: #08b5b3;
    }

        .detail-section.research-section .section-circle .section-icon {
            background-image: url(../images/research.svg);
        }

    .detail-section.publications-section:before {
        background: #23ad8d;
    }

    .detail-section.publications-section .section-circle {
        background: #23ad8d;
    }

        .detail-section.publications-section .section-circle .section-icon {
            background-image: url(../images/publications.svg);
        }

    .detail-section.presentations-section:before {
        background: #039b78;
    }

    .detail-section.presentations-section .section-circle {
        background: #039b78;
    }

        .detail-section.presentations-section .section-circle .section-icon {
            background-image: url(../images/presentations.svg);
        }

    .detail-section.awards-section:before {
        background: #028264;
    }

    .detail-section.awards-section .section-circle {
        background: #028264;
    }

        .detail-section.awards-section .section-circle .section-icon {
            background-image: url(../images/awards.svg);
        }

    .detail-section.leadership-section:before {
        background: #086c3e;
    }

    .detail-section.leadership-section .section-circle {
        background: #086c3e;
    }

        .detail-section.leadership-section .section-circle .section-icon {
            background-image: url(../images/leadership.svg);
        }

    .detail-section .section-head .section-title {
        font-size: 16px;
        margin-left: 8px;
        position: relative;
        top: 10px;
    }

    .detail-section .section-head .section-head-bar {
        background: #f4f4f4;
        flex: 2 1;
        height: 2px;
        margin-left: 15px;
        position: relative;
        top: 20px;
        width: 100%;
    }

    .detail-section .additional-section {
        border: 2px solid transparent;
        border-radius: 10px;
        box-shadow: 0 2px 7px -3px #343a40;
        margin-bottom: 20px;
        margin-left: -5px;
        margin-right: -5px;
        padding: 20px 10px 40px;
    }

    .detail-section .title {
        color: #555;
        font-size: 14px;
        margin-bottom: 5px;
        line-height: 1.1em;
    }

.sidebar-right .section-title {
    font-weight: 500;
    margin-bottom: 10px;
}

.sidebar-right .description {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.basket-card select {
    font-size: 14px;
    width: 184px;
    height: 30px;
    background-position: calc(100% - 2px) 1px;
    padding-right: 32px;
    text-overflow: ellipsis;
}

.sidebar {
    position: absolute;
    background: #fff;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 2px 0 1px -2px rgba(0, 0, 0, 0.12);
    color: #363b43;
    height: 100%;
    left: -310px;
    /*top: 139px;*/
    padding: 20px;
    transition: 0.3s;
    width: 310px;
    height: calc(100% - 145px);
}

    .sidebar select,
    .sidebar input,
    .sidebar textarea {
        background-color: #eeeeee;
        border-color: #eeeeee;
    }

    .sidebar.open {
        left: 0;
    }

        .sidebar.open + .content {
            padding-left: 355px;
        }

@media (max-width: 992px) {
    .sidebar.open + .content .nav-tabs {
        margin-top: 100px;
    }

        .sidebar.open + .content .nav-tabs .btn-add {
            left: 0;
            top: -50px;
        }
}

.sidebar .sidebar-trigger {
    background: white;
    background: linear-gradient(0deg, white 0%, white 100%);
    border: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.12), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
    color: #545359;
    left: 272px;
    position: absolute;
    text-transform: uppercase;
    top: 60px;
    transform: rotate(90deg);
    transition: 0.3s;
}

    .sidebar .sidebar-trigger:hover,
    .sidebar .sidebar-trigger:focus {
        background: #dee3ef;
        background: linear-gradient(0deg, white 0%, #ebeff9 100%);
        border: 0;
        box-shadow: 0 -1px 1px 0 rgba(44, 44, 44, 0.22), 4px -4px 4px 0 rgba(44, 44, 44, 0.08);
    }

    .sidebar .sidebar-trigger img {
        display: inline-block;
        fill: #dee3ef;
        margin-left: 8px;
        width: 16px;
    }

.sidebar .sidebar-content {
    padding: 20px;
}

.sidebar-baskets {
    padding: 0;
}

    .sidebar-baskets .sidebar-content {
        padding: 0;
    }

        .sidebar-baskets .sidebar-content h5 {
            margin-left: 40px;
            margin-top: 10px;
            color: #20558a;
        }

    .sidebar-baskets .sidebar-trigger {
        left: 268px !important;
    }

    .sidebar-baskets .baskets-list {
        list-style: none;
        padding-left: 0;
    }

        .sidebar-baskets .baskets-list li a {
            color: #000;
            display: block;
            width: 100%;
            padding: 10px 40px;
            text-decoration: none;
        }

            .sidebar-baskets .baskets-list li a:hover, .sidebar-baskets .baskets-list li a:focus {
                background-color: #eaf1f7;
                color: #20558a;
            }

            .sidebar-baskets .baskets-list li a.active {
                background-color: #20558a;
                color: #fff;
            }

.sidebar-buttons {
    padding: 0 20px;
    margin-top: 20px;
}

@media (max-width: 766px) {
    .sidebar {
        top: 142px;
    }
}

@media (max-width: 568px) {
    .sidebar {
        top: 178px;
    }
}

.footer {
    background-color: #fff;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    color: #123b56;
    font-size: 13px;
    width: 100%;
    padding: 10px 0;
    position: absolute;
    text-align: left;
    bottom: 0;
    z-index: 500;
}

.footer-logos {
    display: inline-block;
    width: 250px;
}

    .footer-logos img {
        vertical-align: middle;
    }

.logo-footer-hhs {
    height: 45px;
    width: 45px;
}

.logo-footer-nih {
    height: 28px;
    margin-left: 28px;
    width: 44px;
}

.logo-footer-usagov {
    height: 32px;
    margin-left: 20px;
}

.footer-text {
    display: flex;
    float: right;
    font-size: 14px;
    text-align: right;
}

    .footer-text a {
        color: #165a96;
    }

    .footer-text .footer-content {
        border-right: 2px solid #ced4da;
        padding-right: 20px;
    }

    .footer-text .application-details,
    .footer-text .footer-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

        .footer-text .application-details .req-links a:first-child,
        .footer-text .footer-content .req-links a:first-child {
            padding-left: 0;
        }

        .footer-text .application-details .req-links a,
        .footer-text .footer-content .req-links a {
            padding: 0 10px;
        }

    .footer-text .application-details {
        padding-left: 20px;
    }

    .footer-text .application-details,
    .footer-text .footer-content {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
    }

.footer-version {
    font-weight: 500;
}

.login-section {
    margin: 30px 0;
}

.form-wrap {
    border: 1px solid #dee2e6;
    border-radius: 5px;
    padding: 15px 30px 30px;
    background-color: #fff;
    position: relative;
    box-shadow: 0 4px 2px -2px #adb5bd;
}

    .form-wrap input {
        background-color: #EEEEEE;
    }

    .form-wrap:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background-color: #166585;
        background: linear-gradient(90deg, #048cb7 0%, #05abdf 70%);
    }

    .form-wrap .login-buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

        .form-wrap .login-buttons .create-account {
            color: #0477a5;
            text-decoration: underline;
        }

            .form-wrap .login-buttons .create-account:hover, .form-wrap .login-buttons .create-account:focus {
                color: #495057;
            }

.create-account-form {
    max-width: 400px;
    min-width: 286px;
    margin: 0 auto;
    margin-top: 100px;
}

    .create-account-form button {
        width: 100px;
    }

/*# sourceMappingURL=main.min.css.map */
@keyframes ui-progress-spinner-color {
    100%, 0% {
        stroke: #d62d20;
    }

    40% {
        stroke: #0057e7;
    }

    66% {
        stroke: #008744;
    }

    80%, 90% {
        stroke: #ffa700;
    }
}

.p-inputtext {
    padding: 0.5rem 0.5rem !important;
    width: 100% !important;
}

.p-dataview .p-dataview-header {
    background-color: #f2f2f2 !important;
    border: #f2f2f2 !important;
    padding: 0 0 0 0 !important;
    font-weight: normal !important;
}

.p-dataview .p-dataview-content {
    background-color: #f2f2f2 !important;
}

.p-paginator {
    background-color: #f2f2f2 !important;
    margin-top: 0.5rem;
}

    .p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
        background: #28465d !important;
        color: #ffffff;
    }

    .p-paginator .p-paginator-pages .p-paginator-page {
        color: #6f7a83;
        background-color: transparent;
        border: none;
        color: #28465d;
        padding: 0.5rem 0.85rem;
        font-weight: 600;
    }

.filter-btn .btn.close-btn {
    padding: 0.375rem 0.35rem !important;
}

.highlighter {
    background-color: #ffec91;
}

.navbar-container {
    background-color: #0c2d49;
}

    .navbar-container .navbar {
        padding: 0;
    }

        .navbar-container .navbar .nav {
            padding: 10px 15px 0px 40px;
        }

        .navbar-container .navbar .nav-item {
            margin-right: 15px;
        }

            .navbar-container .navbar .nav-item .nav-link {
                text-transform: uppercase;
                color: #fff;
                transition: color 0.3s, background-color 0.1s, padding 0.2s ease-in;
            }

            .navbar-container .navbar .nav-item:hover > a {
                background-color: #fff;
                color: #0c2d49;
            }

        .navbar-container .navbar .nav-tabs .nav-link.active {
            background-color: #e9ecef;
            color: #0c2d49;
            border-color: #e9ecef;
        }

        .navbar-container .navbar .nav-tabs {
            border: none;
        }

        .navbar-container .navbar .navbar-toggler {
            margin-left: 40px;
        }

@media (max-width: 767.98px) {
    .navbar-container .navbar {
        font-size: 14px;
    }
}

.p-multiselect-label {
    white-space: normal !important;
}

.p-multiselect-token {
    font-size: 12px;
    margin: 0.1rem
}

.btn.close-btn {
    position: relative;
    opacity: 0.5;
    padding: 0.25rem 0rem 0.35rem 0.1rem !important;
}

    .btn.close-btn:after {
        content: '×';
        position: absolute;
        color: #000;
        font-size: 20px;
        top: -10px;
    }

    .btn.close-btn:hover, .btn.close-btn:focus {
        opacity: 1;
        box-shadow: none;
    }


.p-multiselect {
    max-width: 26rem;
}

.buttons-group .p-multiselect-label {
    display: block;
    white-space: nowrap !important;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
}

.p-hidden-accessible {
    display: none;
}

.p-divider.p-divider-vertical:before {
    border-left: 1px #bbbbbb;
}


.buttons-group .p-multiselect .p-multiselect-label {
    padding: 0.5rem 0.75rem !important;
}

.buttons-group .p-inputwrapper-filled.p-multiselect.p-multiselect-chip .p-multiselect-label {
    padding: 0.30rem 0.75rem !important;
}

.p-dataview-emptymessage {
    top: 5rem;
    left: 5rem;
    font-size: x-large;
    border: none !important;
}
