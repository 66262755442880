// .footer {
//     margin-top: 30px;
//     padding: 30px 0;
//     font-size: 13px;
//     color: $footer-color;
//     background-color: $footer-bg;
//     text-align: center;
//     ul {
//         padding: 0;
//         list-style: none;
//         li {
//             display: inline-block;
//             a {
//                 padding: 0px 15px;
//                 color: $footer-link-color;
//             }
//             + li a {
//                 border-left: 1px solid #b2b2b2;
//             }
//         }
//     }
//     .footer-tagline {
//         margin: 20px 0 0;
//     }
//   }

  .footer {
    background-color: #fff;
    box-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
    color: #123b56;
    font-size: 13px;
    padding: 10px 0;
    position: relative;
    text-align: left;
    top: 0;
    z-index: 500;
}

.footer-logos {
    display: inline-block;
    width: 250px;
}

.footer-logos img {
    vertical-align: middle;
}

.logo-footer-hhs {
    height: 45px;
    width: 45px;
}

.logo-footer-nih {
    height: 28px;
    margin-left: 28px;
    width: 44px;
}

.logo-footer-usagov {
    height: 32px;
    margin-left: 20px;
}

.footer-text {
    display: flex;
    float: right;
    font-size: 14px;
    text-align: right;
    a {
        color: #165a96;
    }
}

.footer-text .footer-content {
    border-right: 2px solid #ced4da;
    padding-right: 20px;
}

.footer-text .application-details, .footer-text .footer-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-text .application-details .req-links a:first-child, .footer-text .footer-content .req-links a:first-child {
    padding-left: 0;
}

.footer-text .application-details .req-links a, .footer-text .footer-content .req-links a {
    padding: 0 10px;
}

.footer-text .application-details {
    padding-left: 20px;
}

.footer-text .application-details, .footer-text .footer-content {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
}

.footer-version {
    font-weight: 500;
}