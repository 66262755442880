.top-header {
    display: flex;
    justify-content: space-between;
    padding: 10px 25px 10px 25px;
    background: url(../images/header.jpg), $darkBlue;
    background-position: center;
    background-size: cover;

    @media (max-width: 568px) {
        flex-direction: column;

        .user-info {
            margin-top: 20px;
        }
    }

    .nih-details {
        display: flex;

        .logo-nih {
            display: flex;
            fill: white;
            padding: 5px 14px 5px 0;
            position: relative;
            z-index: 10;

            .logo-nih-mark {
                width: 100px;
            }

            .logo-nih-niaid-text {
                width: 150px;
            }

            @include media-breakpoint-down(sm) {
                .logo-nih-niaid-text {
                    display: none;
                }
            }

        }

        .application-details {
            color: #fff;
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 0 16px;
            position: relative;
            z-index: 700;

            h1 {
                font-size: 26px;
                font-weight: normal;
                margin-bottom: 0;

                @media (max-width: 900px) {
                    font-size: 0;
                    visibility: hidden;

                    &::after {
                        content: 'PostDoc';
                        display: block;
                        font-size: 26px;
                        visibility: visible;
                    }
                }
            }

            &::before {
                background: rgba(500, 500, 500, 0.5);
                content: '';
                display: block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 1.5px;
            }
        }
    }

    .user-block {
        display: flex;
        margin-left: auto;
        margin-right: 0;
        align-items: center;

        .user-status {
            display: flex;
            flex-direction: column;
            text-align: right;
            margin-right: 30px;
            font-size: $font-size-sm;
            line-height: 1.3em;
            color: #fff;

            .user-name {
                font-weight: $font-weight-bold;
            }
        }
    }

}

// navigation

.navbar-container {
    background-color: #0C2D49;

    .navbar {
        padding: 0;

        .nav {
            padding: 10px 15px 0px 40px;
        }

        .nav-item {
            margin-right: 15px;

            .nav-link {
                text-transform: uppercase;
                color: $white;
                transition: color 0.3s, background-color 0.1s, padding 0.2s ease-in;
            }

            &:hover>a {
                background-color: $white;
                color: #0C2D49;
                border-bottom-color: #f2f2f2;
            }
        }

        .nav-tabs .nav-link.active {
            background-color: #f2f2f2;
            color: #0C2D49;
            border-color: #f2f2f2;
        }

        .nav-tabs {
            border: none;
        }

        .navbar-toggler {
            margin-left: 40px;
        }
    }

    @media (max-width: 767.98px) {
        .navbar {
            font-size: 14px;
        }
    }

}
