.landing-content {
    padding-top: 30px;
    background: url(../images/header-crop.jpg), DarkBlue;
    background-position: top center;
    background-size: cover;
    min-height: calc(100vh - 300px);
}

    .landing-content .text-section {
        background: rgba(255, 255, 255, 0.4);
        padding: 15px 20px;
        line-height: 1.3;
        font-size: 14px;
        margin-bottom: 30px;
        border-radius: 8px;
        color: #fff;
    }
    .landing-content .text-section-white {
        background: #fff;
        color: black;
    }

    .landing-content .btn-guide {
        min-width: 265px;
        width: 100%;
        background: #fff;
        color: #333;
        font-size: 22px;
        position: relative;
        padding: 0;
        border-radius: 5px;
        text-transform: unset;
        font-weight: 400;
        min-width: 230px !important;
        margin-bottom: 0 !important;
        box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.15), 0 3px 1px -2px rgba(0, 0, 0, 0.12);
        min-height: 80px;
        max-width: 320px;
    }

        .landing-content .btn-guide::before {
            content: '';
            display: block;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            width: 5px;
            background: #05cda0;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }

    .landing-content .MuiButton-label {
        padding-left: 15px;
        line-height: initial;
        text-align: right;
        justify-content: flex-end;
    }

    .landing-content .chevron-right {
        background: linear-gradient(120deg, #038a6b, #04bd93);
        min-width: 90px;
        min-height: 80px;
    }

    .landing-content .btn-guide .chevron-right:after {
        content: '';
        background-image: url(../images/lock.svg);
    }


.heading-white {
    color: #fff;
}


.link-with-arrow {
    color: #c5e5ff;
    position: relative;
    padding-right: 25px;
    transition: .3s;
}

    .link-with-arrow:after {
        content: '';
        position: absolute;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23c5e5ff'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
        width: 20px;
        height: 20px;
        transform: rotate(180deg);
        right: 0;
        top: 2px;
    }

    .link-with-arrow:hover, .link-with-arrow:focus {
        color: #80b4df;
    }

        .link-with-arrow:hover:after, .link-with-arrow:focus:after {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%2380b4df'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12z'/%3E%3C/svg%3E");
        }

/* for input field validation errors */
.field-validation-error {
    color: #c00;
    font-weight: bold;
}

.form-control-validation-error {
    border: 2px solid red !important;
}

/* for required input fields, adds required asterisk after label */
.mandatory:after {
    content: " *";
    color: #c00;
}

.btn-application-width {
    width: 140px !important;
}

.footer-bottom {
    width: 100%;
    position: absolute;
    bottom: 0;
    margin-bottom: -150px;
}

/*.footer-bottom {
    width: 100%;
    position: fixed;
    bottom: 0;
}

.div-footer-padding {
    padding-bottom: 50px;
}
*/
.img-lock {
    padding-top: 6px;
}

.p-button {
    height: 2.5em !important;
}

/*Select Button*/
.p-selectbutton .p-button.p-highlight {
    background: #edebe9 !important;
    border-color: #605e5c !important;
    color: #323130 !important;
}



/*Yes button*/
.p-togglebutton.p-button.p-highlight {
    background: #edebe9 !important;
    border-color: #605e5c !important;
    color: #323130 !important;
}

.div-documents {
    padding-bottom: 40px;
}

.div-right {
    display: inline-block;
    position: absolute;
    right: 0px;
    padding-right: 10px;
}

.div-left {
    display: inline-block;
    position: absolute;
    left: 0px;
    padding-left: 20px;
}

/*Yes checkmark*/
    .p-togglebutton.p-button.p-highlight .p-button-icon-left,
    .p-togglebutton.p-button.p-highlight .p-button-icon-right {
        color: #323130 !important;
    }

/*No button*/
.p-togglebutton.p-button {
    background: #ffffff;
    border: 1px solid #605e5c !important;
    color: #323130 !important;
    transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
}

/* .p-dialog .p-dialog-header {
    background: #edebe9 !important;
}

.p-dialog .p-dialog-content {
    background: #edebe9 !important;
}


 */

.degree-dropdown {
    height: 200px;
    width: 200px;
    overflow-y: scroll;
}
